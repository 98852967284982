import React from 'react'
import { Link, graphql } from 'gatsby'
import Masonry from 'react-masonry-component'
import Img from 'gatsby-image'
import Layout from "../components/layout"

const IndexPage = ({ data }) => (
  <Layout>
    <Masonry className="showcase">
      {data.allDatoCmsManufacturer.edges.map(({ node: manufacturer }) => (
        <div key={manufacturer.id} className="showcase__item">
          <figure className="card">
            <Link to={`/manufacturers/${manufacturer.slug}`} className="card__image">
              <Img fluid={manufacturer.coverImage.fluid} />
            </Link>
            <figcaption className="card__caption">
              <h6 className="card__title">
                <Link to={`/manufacturers/${manufacturer.slug}`}>{manufacturer.title}</Link>
              </h6>
              <div className="card__description">
                <p>{manufacturer.excerpt}</p>
              </div>
            </figcaption>
          </figure>
        </div>
      ))}
    </Masonry>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query IndexQuery {
    allDatoCmsManufacturer(sort: { fields: [position], order: ASC }, filter: { enabled: { eq: true } }) {
      edges {
        node {
          id
          title
          slug
          excerpt
          coverImage {
            fluid(maxWidth: 450, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
  }
`
